import React from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

const YandexMap = ({width}) => {
  return (
    <YMaps apiKey="44eca2f2-98b8-42a0-bd3a-8d709ae2867f">
      <div style={{width: '100%', height: '100%'}}>
        <Map
          defaultState={{ center: [55.162649, 61.290444], zoom: 17 }}
          className='Ymap_content'
          style={ width > 1150 ? {  maxWidth: '100%', height: '495px' } : width > 700 ? { maxWidth: '700px', height: '495px' } : { maxWidth: '500px', height: '300px' }}
        >
          <Placemark geometry={[55.162649, 61.290444]} 
            options={{
            iconColor: '#FFA500', // оранжевый цвет
            preset: 'islands#darkOrangeDotIcon' // стиль метки с припиской
            }}
            properties={{
            iconCaption: 'Академика Королёва, д. 33, 10' // приписка к метке
            }}
          />
        </Map>
      </div>
    </YMaps>
  );
};

export default YandexMap;

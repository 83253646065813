import { React, useState } from "react"
import InputMask from 'react-input-mask';
import ApiPost from "../../API/ApiPost";
import { useFetching } from "../../hooks/useFetching";

const Call_po = () => {

    const [check, setCheck] = useState(true)

    const [fetching, isLoading] = useFetching( async (obj) => {
        const res = await ApiPost.email_send(obj)
    })

    function Submit(e) {
        e.preventDefault()

        const name = e.target.elements.name.value;
        const phone = e.target.elements.phone?.value;
        const email = e.target.elements.email?.value;
        
        let obj = {
            name: name,
            phone: phone,
            email: email,

            subject: "Новый заявка! (Перезвонить)",
            message: ``
        }

        if(check) {
            fetching(obj)
        }
    }

    return(
        <div className="consult_po">
            <div className="h-25-300-m">Мы перезвоним вам!</div>
            <form onSubmit={Submit}>
                <input name="name" type="text" className="base_input h-20-500-m" placeholder="Ваше имя" required/>
                <InputMask name="phone" className="base_input h-20-500-m" mask="+7 (999) 999-99-99" placeholder="+7 (999) 999-99-99" required/>
                <div className="row-a-15">
                    <button name="check" type="button" className={`checkbox_btn ${check ? 'active' : ''}`} onClick={() => setCheck(!check)}></button>
                    <span className="h-14-400-m">Я согласен с <a href="#!" className="orange" style={{textDecoration: 'underline'}}>политикой конфиденциальности</a></span>
                </div>
                <button type="submit" className="submit_btn h-18-500-m">Отправить заявку</button>
            </form>
        </div>
    )
} 

export default Call_po
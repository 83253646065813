import axios from "axios"
import { url } from "../utils/consts";

export default class ApiPost {

    static async get_cases(page) {
        console.log('get cases', page, `${url}/get_cases/`)
        const response = await axios.post(`${url}/get_cases/`, {
            page: page,
        })

        return response;
    }

    static async email_send(res) {
        console.log('otpravka',res)
        const response = await axios.post(`${url}/email/send/`, res)

        return response;
    }


}
import r1 from '../img/icons/offer/r1.png'
import r2 from '../img/icons/offer/r2.svg'
import r3 from '../img/icons/offer/r3.png'
import quote from '../img/icons/quote.svg'
import block_3_img from '../img/block_3_img.png'
import pre_block_icon from '../img/pre_block_icon.png'
import type_1 from '../img/type_1.png'
import type_1_w from '../img/type_1_w.png'
import type_2 from '../img/type_2.jpg'
import type_2_w from '../img/type_2_w.png'
import type_3 from '../img/type_3.jpg'
import manager from '../img/manager.png'
import papers_clips from '../img/papers_clips.png'
import p_1 from '../img/icons/p_1.png'
import p_2 from '../img/icons/p_2.png'
import stars_icon from '../img/icons/stars_icon.png'
import gogle_maps from '../img/icons/gogle_maps.png'
import flamp from '../img/icons/flamp.png'
import dgis from '../img/icons/2gis.png'
import Callmail_icon from '../img/icons/call-mail_icon.png'
import vk_icon from '../img/icons/socials/vk.svg'
import facebook_icon from '../img/icons/socials/facebook.svg'
import inst_icon from '../img/icons/socials/inst.svg'
import youtube_icon from '../img/icons/socials/youtube.svg'

const img_pack = {
    r1,
    r2,
    r3,
    quote,
    block_3_img,
    pre_block_icon,
    type_1,
    type_1_w,
    type_2,
    type_2_w,
    type_3,
    manager,
    papers_clips,
    p_1,
    p_2,
    stars_icon,
    gogle_maps,
    flamp,
    dgis,
    Callmail_icon,
    vk_icon,
    inst_icon,
    facebook_icon,
    youtube_icon,
}

export default img_pack
import { Splide, SplideTrack, SplideSlide} from '@splidejs/react-splide'
import React from "react"
import { reviews_slider_pack } from '../../utils/consts';

const Reviews_slider = () => {

    return (
        <div className="Reviews_example">
            <div className="splide-block">
                    <Splide hasTrack={ false } options={{
                            rewind: true,
                            gap: '20px',
                            perPage: 2,
                            pagination: false,
                            breakpoints: {
                                800: {
                                  perPage: 1,
                                }
                            }
                        }}>
                        <SplideTrack>
                            {reviews_slider_pack?.map((el,i) => (
                                <SplideSlide key={[el.name,i]}>
                                    <div className='reviw_block'>
                                        <div className='row-a-20'>
                                            <img src={el.photo} />
                                            <span className='h-16-600-m black'>{el.name}</span>
                                            <img src={el.platform_img} />
                                        </div>
                                        <div className='h-14-400-m black' dangerouslySetInnerHTML={{ __html: el.txt}}></div>
                                    </div>
                                </SplideSlide>
                            ))}
                        </SplideTrack>

                        <div className="splide__arrows">
                            <button className="splide__arrow splide__arrow--prev"></button>
                            <button className="splide__arrow splide__arrow--next"></button>
                        </div>
                    </Splide>
                </div>
        </div>
    )
}

export default Reviews_slider;
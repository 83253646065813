import React from "react"
import Header from "./components/Header";
import { Link } from "react-router-dom";

const Page_privacyPolicy = () => {

    return (
        <>
            <div className="block">
                <div className="container">
                    <Header />
                    <div className="docs_block">
                        <div className="docs_title">
                            <div className="h-16-500-m orange">Изменено от 10 апреля 2024</div>
                            <div className="h-50-700-m c-1">Политика конфиденциальности</div>
                        </div>
                        <div className="docs_content">
                            <div className="docs_content_example">
                                <div className="h-24-700-m c-1">Введение</div>
                                <div className="h-18-500-m c-2">Настоящая Политика конфиденциальности определяет порядок сбора, использования и защиты информации, которую мы получаем от пользователей нашего сайта.</div>
                            </div>
                            <div className="docs_content_example">
                                <div className="h-24-700-m c-1">Сбор информации</div>
                                <div className="h-18-500-m c-2">Мы можем собирать различные виды информации от пользователей, включая персональные данные, такие как имя, адрес электронной почты, номер телефона и другую контактную информацию. Эта информация собирается только при добровольном её предоставлении пользователями.</div>
                            </div>
                            <div className="docs_content_example">
                                <div className="h-24-700-m c-1">Использование информации</div>
                                <div className="h-18-500-m c-2">Мы используем предоставленную пользователем информацию для обеспечения качественного предоставления услуг, ответов на запросы пользователей, а также для улучшения работы нашего сайта и разработки новых услуг.</div>
                            </div>
                            <div className="docs_content_example">
                                <div className="h-24-700-m c-1">Защита информации</div>
                                <div className="h-18-500-m c-2">Мы предпринимаем все необходимые меры для защиты информации, собранной от пользователей, от несанкционированного доступа, изменения, раскрытия или уничтожения. Информация хранится на безопасных серверах и защищается средствами защиты информации.</div>
                            </div>
                            <div className="docs_content_example">
                                <div className="h-24-700-m c-1">Раскрытие информации третьим лицам</div>
                                <div className="h-18-500-m c-2">Мы не раскрываем персональную информацию пользователей третьим лицам без их согласия, за исключением случаев, предусмотренных законодательством.</div>
                            </div>
                            <div className="docs_content_example">
                                <div className="h-24-700-m c-1">Согласие с политикой конфиденциальности</div>
                                <div className="h-18-500-m c-2">Используя наш сайт, пользователи подтверждают свое согласие с нашей Политикой конфиденциальности. Если у пользователей возникают вопросы относительно нашей Политики, они могут связаться с нами для получения дополнительной информации.</div>
                            </div>
                            <div className="docs_content_example">
                                <div className="h-18-500-m c-2">Дата последнего обновления: 10.04.2024</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Page_privacyPolicy;
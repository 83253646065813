import React, { createContext, useEffect, useState } from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import Layout from "./Pages/Layout"
import Main from "./Pages/Main"
import { Page404_Root } from "./utils/consts"
import { publicRoutes } from "./utils/routes"
import Popup from "./Pages/components/Popup"
import Consult_po from "./Pages/components/Consult_po"
import Quiz_po from "./Pages/components/Quiz_po"
import Call_po from "./Pages/components/Call_po"

export const Context = createContext(null)

const App = () => {

  const location = useLocation()
  const prevLocation = location.state?.prevLocation

  return (
    <Context.Provider
      value={{}}
    >
      <div>
        <Routes location={prevLocation || location}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Main />}/>
            {publicRoutes.map(({path, element}) => 
              <Route key={path} path={path} element={element}/>
            )}
            <Route path="*" element={ <Navigate to={`${Page404_Root}`} />}/> 
          </Route>
        </Routes>
        {prevLocation && (
          <Routes>
            <Route path="Consult_po" element={<Popup><Consult_po /></Popup>} />
            <Route path="Quiz_po" element={<Popup><Quiz_po /></Popup>} />
            <Route path="Call_po" element={<Popup><Call_po /></Popup>} />
          </Routes>
        )}
      </div>
    </Context.Provider>
  )
}

export default App;

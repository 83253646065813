import Page404 from "../Pages/Page404";
import Page_personalPolicy from "../Pages/Page_personalPolicy";
import Page_privacyPolicy from "../Pages/Page_privacyPolicy";
import { Page404_Root, Page_personalPolicy_Root, Page_privacyPolicy_Root } from "./consts";

export const publicRoutes = [
    {
        path: Page404_Root,
        element: <Page404 />
    },
    {
        path: Page_privacyPolicy_Root,
        element: <Page_privacyPolicy />
    },
    {
        path: Page_personalPolicy_Root,
        element: <Page_personalPolicy />
    },
]
import { React, useState } from "react"
import InputMask from 'react-input-mask';
import { Page_privacyPolicy_Root, questions } from "../../utils/consts";
import { useEffect } from "react";
import { useFetching } from "../../hooks/useFetching";
import ApiPost from "../../API/ApiPost";
import { Link, useNavigate } from "react-router-dom";

const Quiz_po = () => {

    const navigate = useNavigate()

    const [selectedAnswer, setSelectedAnswer] = useState({})
    const [activeQuestion, setActiveQuestion] = useState(0)
    const { question, numb , choices, info } = questions[activeQuestion]
    const totalQuestions = questions.length
    
    useEffect(() => {
        console.log('selectedAnswer', selectedAnswer )
    },[selectedAnswer])
    
    useEffect(() => {
        const buttons = document.querySelectorAll(`button[data-quiz-btn-page="${numb}"]`);
        choices.forEach((el) => {
            if(el.type === 'input' || el.type === 'inputMask') {
                if(typeof selectedAnswer[numb] !== 'object') {
                    setSelectedAnswer({...selectedAnswer, [numb]: {...selectedAnswer[numb],[el.unic] : ''}})
                }
            }
        })
        buttons.forEach((el) => {
            if (el.querySelector('.quiz-btn-title').textContent === selectedAnswer[numb]) {
                el.classList.add('active')
            }
        })
    },[choices])

    function onClickNext() {
        if(typeof selectedAnswer[numb] === 'object') {
            if (Object.values(selectedAnswer[numb]).every(el => el !== '')) {
                setActiveQuestion(activeQuestion + 1);
            } else {
                alert('не выбрано');
            }
        } else {
            if (selectedAnswer[numb] !== '' && selectedAnswer[numb]) {
                setActiveQuestion(activeQuestion + 1)
            } else {
                alert('не выбрано')
            }
        }
    }

    const [fetching, isLoading] = useFetching( async (obj) => {
        const res = await ApiPost.email_send(obj)
        console.log(res)
    })

    function onSubmit() {
        if (Object.values(selectedAnswer[numb]).every(el => el !== '')) {
            let obj = {
                name: selectedAnswer[totalQuestions].name,
                phone: selectedAnswer[totalQuestions].tel,
                email: selectedAnswer[totalQuestions].email,

                subject: "Новый заявка! (квиз)",
                message: `<div>${Object.entries(selectedAnswer).map(([key, value]) => (
                    `<span>Вопрос ${key}. Ответ: ${typeof value === 'object' ? Object.values(value)?.map((el) => el) : value}</span><br/>`
                ))}
                </div>`
            }
            fetching(obj)
            navigate(-1)
        } else {
            alert('не выбрано');
        }
    
    }

    function onClickBack() {
        setActiveQuestion(activeQuestion - 1)
    }
    
    function onClickChoice(e) {
        const buttons = document.querySelectorAll(`button[data-quiz-btn-page="${numb}"]`);
        buttons.forEach((el) => {
            if (el.classList.contains('active')) {
                el.classList.remove('active')
            }
        })
        e.currentTarget.classList.add('active')
        console.log(e.currentTarget.querySelector('.quiz-btn-title').textContent)
        buttons.forEach((el) => {
            if (el.classList.contains('active')) {
                setSelectedAnswer({...selectedAnswer, [numb]: e.currentTarget.querySelector('.quiz-btn-title').textContent})
            }
        })
        totalQuestions > numb && setActiveQuestion(activeQuestion + 1)
    }

    function onInputChange(e) {
        setSelectedAnswer({...selectedAnswer, [numb]: {...selectedAnswer[numb],[e.currentTarget.getAttribute('data-quiz-input-unic')] :e.currentTarget.value}})
    }

    return(
        <div className="quiz">
            <div>
                <span className="h-25-300-m black">Шаг {numb} / {totalQuestions}</span>
                <span className="h-14-300-m black">Передавая информацию сайту, вы принимаете условия <Link to={`/${Page_privacyPolicy_Root}`} className="orange">Пользовательского соглашения</Link></span>
            </div>
            <div>
                {numb != totalQuestions ?
                <div className="col-40">
                    <span className="quiz-title h-30-700-m black">Узнайте стоимость ремонта и получите подарок! </span>
                    <span className="h-25-300-m">Среднее время прохождения опроса - 90 секунд.</span>
                </div>
                :
                <div className="col-40">
                    <span className="quiz-title h-30-700-m black">Оставьте свои данные</span>
                    <span className="h-25-300-m">Наш менеджер свяжется с вами и озвучит стоимость</span>
                </div>
                }
                <div className='col-60'>
                    {info ?
                    <div style={{display: "flex", flexDirection: 'column', gap: '10px'}}>
                        <span className="h-30-600-m black">{numb}. {question}</span>
                        <div className="quiz-info">
                            <span></span>
                            <span className="h-14-300-m" dangerouslySetInnerHTML={{ __html: info }}></span>
                        </div>
                    </div>
                    : question &&
                    <span className="h-30-600-m black">{numb}. {question}</span>
                    }
                    <div className="col-30" style={numb === totalQuestions ? {alignItems: 'center'} : {}}>
                        {choices.map((el) => (
                            el.type === 'btn_img' ?
                            <button key={[el.title, numb]} data-quiz-btn-page={numb} onClick={onClickChoice}>
                                <img src={el.img} alt="" />
                                <div className="quiz_btn_img_row">
                                    <span className="h-18-500-m quiz-btn-title">{el.title}</span>
                                    <span></span>
                                </div>
                            </button>
                            : el.type === 'input' ?
                            <input 
                                key={[el.unic]} 
                                data-quiz-input-unic={el.unic} 
                                type={`${el.inputType}`} 
                                className={`base_input h-20-300-m`} 
                                placeholder={el.placeholder} 
                                value={selectedAnswer[numb]?.[el.unic] || ''}
                                onChange={onInputChange}
                            />
                            : el.type === 'btn_txt' ?
                            <button key={[el.title, numb]} data-quiz-btn-page={numb} onClick={onClickChoice}>
                                <div className="quiz_btn_txt_row">
                                    <span></span>
                                    <span className="h-18-500-m quiz-btn-title">{el.title}</span>
                                </div>
                            </button>
                            : el.type === 'inputMask' &&
                            <InputMask 
                                key={[el.unic]} 
                                data-quiz-input-unic={el.unic} 
                                className={`base_input h-20-300-m`} 
                                mask="+7 (999) 999-99-99" 
                                placeholder="+7 (999) 999-99-99"
                                value={selectedAnswer[numb]?.[el.unic] || ''}
                                onChange={onInputChange}
                            />
                        ))}
                    </div>
                    <div className="quiz_main_btns_row">
                        {(numb > 1) &&
                        <button className="quiz_back_btn" onClick={onClickBack}>
                            <span></span>
                            <span className="h-18-500-m">Предыдущий шаг</span>
                        </button>
                        }
                        {(totalQuestions > numb) &&
                        <button className="main_btn quiz_next_btn h-18-500-m" onClick={onClickNext}>Следующий шаг</button>
                        }
                        {(totalQuestions === numb) &&
                        <button className="main_btn quiz_next_btn h-18-500-m" onClick={onSubmit}>Отправить</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
} 

export default Quiz_po
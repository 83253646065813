import React from "react"
import { Link, useLocation } from "react-router-dom";

const Ow_example = ({el,i}) => {

    const location = useLocation()

    return (
        <div className="Ow-example">
            <div className="row-a-15">
                <img src={el.img} />
                <span className="h-50-700-m orange">0{i+1}</span>
            </div>
            <div className="col-15">
                <span className="h-16-600-m black">{el.title}</span>
                <span className="h-15-400-m black">{el.desc}</span>
                {el.link === 'consult' &&
                    <Link className="h-14-400-m orange" to='/Consult_po' state={{prevLocation: location}} style={{textDecoration: 'underline', textAlign: 'left'}}>{el.linkTxt}</Link>
                    // <button className="h-14-400-m orange" style={{textDecoration: 'underline', textAlign: 'left'}}>{el.linkTxt}</button>
                }
                {el.link === 'request' &&
                    <Link className="h-14-400-m orange" to='/Consult_po' state={{prevLocation: location}} style={{textDecoration: 'underline', textAlign: 'left'}}>{el.linkTxt}</Link>
                    // <button className="h-14-400-m orange" style={{textDecoration: 'underline', textAlign: 'left'}}>{el.linkTxt}</button>
                }
                {el.link === 'docs' &&
                    // <Link className="h-14-400-m orange" to='/Consult_po' state={{prevLocation: location}} style={{textDecoration: 'underline', textAlign: 'left'}}>{el.linkTxt}</Link>
                    <button className="h-14-400-m orange" style={{textDecoration: 'underline', textAlign: 'left'}}>{el.linkTxt}</button>
                }
            </div>
        </div>
    )
}

export default Ow_example;
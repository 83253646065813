import React from "react"
import { Link } from "react-router-dom";
import { Page_personalPolicy_Root } from "../../utils/consts";

const Footer = () => {

    return (
        <footer>
            <div className="container">
                <div className="footer_row">
                    <span className="h-12-400-m white">ИП Захарчук А.В. ИНН: 745210627384 <br/>КПП: ОГРН: 319745600048412</span>
                    <Link className="h-12-400-m white" to={`/${Page_personalPolicy_Root}`}>Политика обработки <br/>персональных данных</Link>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
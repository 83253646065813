import React from "react"
import Header from "./components/Header";
import { Link } from "react-router-dom";

const Page_personalPolicy = () => {

    return (
        <>
            <div className="block">
                <div className="container">
                    <Header />
                    <div className="docs_block">
                        <div className="docs_title">
                            <div className="h-16-500-m orange">Изменено от 10 апреля 2024</div>
                            <div className="h-50-700-m c-1">Политика обработки персональных данных</div>
                        </div>
                        <div className="docs_content">
                            <div className="docs_content_example">
                                <div className="h-24-700-m c-1">Общие положения</div>
                                <div className="h-18-500-m c-2">Настоящая Политика обработки персональных данных определяет порядок сбора, хранения, использования, передачи и защиты персональной информации пользователей сайта.</div>
                            </div>
                            <div className="docs_content_example">
                                <div className="h-24-700-m c-1">Сбор и использование персональной информации</div>
                                <div className="h-18-500-m c-2">Мы собираем персональную информацию только при добровольном её предоставлении пользователями. Это может включать в себя имя, адрес электронной почты, номер телефона и другие контактные данные. Мы используем эту информацию только для целей, указанных при её сборе, а также для улучшения качества наших услуг и обеспечения лучшего обслуживания клиентов.</div>
                            </div>
                            <div className="docs_content_example">
                                <div className="h-24-700-m c-1">Хранение и защита персональной информации</div>
                                <div className="h-18-500-m c-2">Мы предпринимаем все необходимые меры для защиты персональной информации от несанкционированного доступа, изменения, раскрытия или уничтожения. Персональная информация хранится на безопасных серверах и защищается средствами защиты информации</div>
                            </div>
                            <div className="docs_content_example">
                                <div className="h-24-700-m c-1">Передача персональной информации третьим лицам</div>
                                <div className="h-18-500-m c-2">Мы не передаем персональную информацию третьим лицам без согласия пользователя, за исключением случаев, предусмотренных законодательством.</div>
                            </div>
                            <div className="docs_content_example">
                                <div className="h-24-700-m c-1">Изменения в политике</div>
                                <div className="h-18-500-m c-2">Мы оставляем за собой право вносить изменения в настоящую Политику обработки персональных данных. Изменения вступают в силу с момента их публикации на сайте.</div>
                            </div>
                            <div className="docs_content_example">
                                <div className="h-24-700-m c-1">Согласие с политикой</div>
                                <div className="h-18-500-m c-2">Используя наш сайт, вы подтверждаете свое согласие с нашей Политикой обработки персональных данных. Если у вас есть вопросы относительно нашей Политики, пожалуйста, свяжитесь с нами.</div>
                            </div>

                            <div className="docs_content_example">
                                <div className="h-18-500-m c-2">Дата последнего обновления: 10.04.2024</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Page_personalPolicy;
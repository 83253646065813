import {React, useRef, useEffect} from "react"
import { useNavigate } from "react-router-dom"
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const Popup = ({children}) => {
    const navigate = useNavigate()
    const modalRef = useRef()

    useEffect(() => {
        const observerRefValue = modalRef.current;
        disableBodyScroll(observerRefValue);
        return () => {
          if (observerRefValue) {
            enableBodyScroll(observerRefValue);
          }
        };
      }, []);

    return(
        <div ref={modalRef} className={"overlay active"} onClick={()=> navigate(-1)}>
            <div className="po_container">
                <div className="popup" onClick={e => e.stopPropagation()}>
                  <div className="close_po_btn" onClick={()=> navigate(-1)}/>
                  {children}
                </div>
            </div>
        </div>
    )
}

export default Popup
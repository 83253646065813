import a1 from '../img/11.jpg'
import a2 from '../img/12.jpg'

import p1 from '../img/icons/principles/1.png'
import p2 from '../img/icons/principles/2.png'
import p3 from '../img/icons/principles/3.png'
import p4 from '../img/icons/principles/4.png'

import p21 from '../img/icons/principles/2.1.png'
import p22 from '../img/icons/principles/2.2.png'
import p23 from '../img/icons/principles/2.3.png'
import p24 from '../img/icons/principles/2.4.png'

import p31 from '../img/icons/principles/3.1.png'
import p32 from '../img/icons/principles/3.2.png'
import p33 from '../img/icons/principles/3.3.png'
import p34 from '../img/icons/principles/3.4.png'

import p41 from '../img/icons/principles/4.1.png'
import p42 from '../img/icons/principles/4.2.png'
import p43 from '../img/icons/principles/4.3.png'
import p44 from '../img/icons/principles/4.4.png'

import ow1 from '../img/icons/workOrder/1.png'
import ow2 from '../img/icons/workOrder/2.png'
import ow3 from '../img/icons/workOrder/3.png'
import ow4 from '../img/icons/workOrder/4.png'
import ow5 from '../img/icons/workOrder/5.png'
import ow6 from '../img/icons/workOrder/6.png'

import mail_icon from '../img/icons/reviews/mail_icon.png'

import review_photo_1 from '../img/icons/reviews/review_photo_1.png'
import review_photo_2 from '../img/icons/reviews/review_photo_2.png'

import i_1 from '../img/quiz/1/i_1.png'
import i_2 from '../img/quiz/1/i_2.png'

import i_21 from '../img/quiz/2/i_2.1.png'
import i_22 from '../img/quiz/2/i_2.2.png'
import i_23 from '../img/quiz/2/i_2.3.png'

import i_31 from '../img/quiz/3/i_3.1.png'
import i_32 from '../img/quiz/3/i_3.2.png'

import i_41 from '../img/quiz/4/i_4.1.png'
import i_42 from '../img/quiz/4/i_4.2.png'
import i_43 from '../img/quiz/4/i_4.3.png'
import i_44 from '../img/quiz/4/i_4.4.png'

import i_81 from '../img/quiz/8/i_8.1.png'
import i_82 from '../img/quiz/8/i_8.2.png'

export const Page404_Root = '404'
export const Page_privacyPolicy_Root = 'Privacy_policy'
export const Page_personalPolicy_Root = 'Personal_policy'

export const url = 'https://serv.stroy-chel.ru'

const objects1 = [
    {
        title: 'Капитальный ремонт 3-комнатной <br/>квартиры по дизайн проекту',
        tegs: ['2022','3-комнатная','130 м²'],
        imgs: [a1,a2,a2,a1],
        info_house: 'Ул. Доватора, д. 43 А. <br /> Панельный дом',
        initial_state: 'Штукатурка от застройщика',
        work_time: '14 недель',
    },
    {
        title: 'Капитальный ремонт 3-комнатной <br/>квартиры по дизайн проекту',
        tegs: ['2021','3-комнатная','90 м²'],
        imgs: [a1,a2,a2,a1],
        info_house: 'Ул. Доватора, д. 43 А. <br /> Панельный дом',
        initial_state: 'Штукатурка от застройщика',
        work_time: '14 недель',
    },
    {
        title: 'Капитальный ремонт 2-комнатной <br/>квартиры по дизайн проекту',
        tegs: ['2022','2-комнатная','56 м²'],
        imgs: [a1,a2,a2,a1],
        info_house: 'Ул. Доватора, д. 43 А. <br /> Панельный дом',
        initial_state: 'Штукатурка от застройщика',
        work_time: '14 недель',
    },
]
export default objects1;

export const princip1 = [
    {
        img: p1,
        title: 'Выполняем ремонт комплексно',
        desc: 'Предоставляем услуги "под ключ", где наш прораб берет на себя все риски, проблемы и взаимодействие с управляющими компаниями.',
    },
    {
        img: p2,
        title: 'Ведём регулярную отчётность',
        desc: 'Создаем чат в выбранном вами мессенджере, который наиболее удобен для вас, где мы еженедельно предоставляем отчеты в формате фото и видео для того, чтобы вы могли в реальном времени отслеживать прогресс работы.',
    },
    {
        img: p3,
        title: 'Помогаем с материалами',
        desc: 'Стремимся к высокому качеству работ, приобретая только материалы, которые прошли проверку на качество. Это позволяет нам обеспечить превосходный результат и, в то же время, эффективно использовать ресурсы, чтобы сделать процесс более экономичным.',
    },
    {
        img: p4,
        title: 'Наводим уборку при сдаче объекта',
        desc: 'Профессиональный клининг после завершения комплексного ремонта помещения перед его сдачей.',
    },
]
export const princip2 = [
    {
        img: p21,
        title: 'Следим за соблюдением СНиП',
        desc: 'Тщательно следим за соблюдением всех строительных норм и правил, идущих в ногу с требованиями современных стандартов. ',
    },
    {
        img: p22,
        title: 'Есть патент на наш вид работ',
        desc: 'Обладаем патентом на проведение отделочных работ, включая ремонт жилых помещений и других строительных построек.',
    },
    {
        img: p23,
        title: 'Работаем официально',
        desc: 'Зарегистрированы как ИП, строго соблюдаем налоговое законодательство и систематически оформляем наши работы с помощью актов, фиксируя выполненные задачи 2 раза в месяц.',
    },
    {
        img: p24,
        title: 'Мы не пропадём с вашими деньгами',
        desc: 'Заключаем официальный договор на выполнение ремонтных работ, гарантируя прозрачные условия и взаимные обязательства, что обеспечивает защиту интересов как заказчика, так и нашей компании',
    },
]
export const princip3 = [
    {
        img: p31,
        title: 'Мы открыты',
        desc: 'Мы присутствуем во всех основных социальных сетях, на платформах 2ГИС, Google Картах и Яндекс Картах, где наши клиенты оставляют свои отзывы.',
    },
    {
        img: p32,
        title: 'Собираем обратную связь',
        desc: 'После сдачи объекта всегда получаем отзыв о работе, чтобы становиться лучше.',
    },
    {
        img: p33,
        title: 'Клиенты из за рубежа',
        desc: 'Мы обслуживаем клиентов не только из Челябинска, но и из других стран, у которых есть недвижимость в нашем городе.',
    },
    {
        img: p34,
        title: 'Ведем прозрачную отчетность',
        desc: 'Поддерживаем честные и прозрачные отношения с нашими клиентами, предоставляя им полную информацию о ходе работ, затратах и выполненных задачах',
    },
]
export const princip4 = [
    {
        img: p41,
        title: 'Не работаем в жилых объектах',
        desc: 'Не работаем на объектах, где проживают люди. Мы уважаем комфорт и стремимся сделать процесс ремонта максимально беспроблемным и безопасным для всех сторон.',
    },
    {
        img: p42,
        title: 'Наша команда — профессионалы',
        desc: 'Все наши мастера обладают не менее чем 7-летним опытом работы и постоянно совершенствуют свои навыки и проходят ежегодное обучение.',
    },
    {
        img: p43,
        title: 'Соблюдаем режим работы и отдыха',
        desc: 'Мы строго соблюдаем режим работы и отдыха для наших сотрудников. В ночное время на объекте присутствуют только материалы.',
    },
    {
        img: p44,
        title: 'Проанализируем смету конкурентов',
        desc: 'Мы предоставляем бесплатный анализ предложений конкурентов, подробно изучая каждый аспект и раскрывая все тонкости на основе фактов.',
    },
]

export const orderWork = [
    {
        img: ow1,
        title: 'Оставляете заявку и получаете консультацию',
        desc: 'Мы проводим консультацию, в ходе которых задаем множество вопросов, чтобы определить нужды в ремонте и особенности, на которые следует обратить внимание.',
        link: 'consult',
        linkTxt: 'Оставить заявку на консультацию →',
    },
    {
        img: ow2,
        title: 'Выезжаем на объект, проводим замеры',
        desc: 'Познакомимся, выполним замеры, поможем в выборе материалов и поделимся советами, как избежать лишних затрат в вашем конкретном случае.',
        link: 'request',
        linkTxt: 'Оставить заявку на замер →',
    },
    {
        img: ow3,
        title: 'Заключаем договор, составляем график выполнения и оплаты работ',
        desc: 'Пригласим вас в наш офис, угощая вкусным чаем или кофе, чтобы обсудить все детали. Заключим договор и обсудим график выполнения работ и оплаты, рассмотрим все ключевые вопросы.',
    },
    {
        img: ow4,
        title: 'Осуществляем ремонтные работы вашего помещения',
        desc: 'Мы закупаем все необходимые материалы, доставляем их на объект и выполняем работы поэтапно, закрывая акты выполненных работ и предоставляя полную отчетность через мессенджер. По завершении ремонта мы проводим уборку и вывозим мусор, чтобы объект был готов к использованию.',
    },
    {
        img: ow5,
        title: 'Проводим проверку качества выполненных работ',
        desc: 'Каждый наш объект проходит тщательную проверку качества выполненных работ, начиная с прораба и заканчивая руководителем проекта. По завершении проверки вы можете оставить свой отзыв о нашей работе.',
    },
    {
        img: ow6,
        title: 'Составляем акты выполненных работ, рассчитываемся',
        desc: 'Мы предоставляем гарантию на выполненные работы от 1 до 3 лет. В случае обнаружения дефектов мы бесплатно устраняем их, обеспечивая безопасность и уверенность в качестве нашей работы.',
        link: 'docs',
        linkTxt: 'Ознакомиться с договором →',
    },
]

export const reviews_slider_pack = [
    {
        photo: review_photo_1,
        name: 'Евгений Черепанов',
        platform_img: mail_icon,
        txt: `<span>Было непросто найти подходящую бригаду для нашего ремонта, но, благодаря тщательному поиску, мы остановились на компании СТРОЙЧЕЛ. Нам была предоставлена детальная смета, включающая не только материалы, но и расценки на работы, что помогло нам четко спланировать бюджет. Это было первым сигналом профессионализма.<br/><br/>
        Хочу выразить огромную благодарность Александру Чуватаеву и всей его команде за высокое качество работ и строгое соблюдение сроков. В результате мы получили не просто ремонт, а идеально выполненную работу по возведению гипсоконструкций, заливке пола, разводке электрики и выравниванию стен. Спасибо за ваше профессионализм и внимательное отношение к деталям!</span>`,
    },
    {
        photo: review_photo_2,
        name: 'Евгений Черепанов',
        platform_img: mail_icon,
        txt: `<span>Долго мы искали подходящую бригаду для нашего ремонта, и в итоге выбрали компанию СТРОЙЧЕЛ! Наш проект включал в себя возведение гипсоконструкций, заливку пола, разводку электрики и выравнивание стен.<br/>
        Мы были приятно удивлены тем, что нам предоставили подробную смету, охватывающую все от материалов до расценок на работы. Это было важно для нас при планировании бюджета нашего будущего ремонта, и ни одна другая компания не предоставила нам такой информации.<br/>
        Хотим выразить огромную благодарность Александру Чуватаеву и его бригаде за высокое качество работы и строгое соблюдение сроков. Ваш профессионализм и внимательное отношение к деталям сделали наш ремонт беззаботным и качественным. Большое спасибо!</span>`,
    },
    {
        photo: review_photo_1,
        name: 'Евгений Черепанов',
        platform_img: mail_icon,
        txt: `<span>Было непросто найти подходящую бригаду для нашего ремонта, но, благодаря тщательному поиску, мы остановились на компании СТРОЙЧЕЛ. Нам была предоставлена детальная смета, включающая не только материалы, но и расценки на работы, что помогло нам четко спланировать бюджет. Это было первым сигналом профессионализма.<br/><br/>
        Хочу выразить огромную благодарность Александру Чуватаеву и всей его команде за высокое качество работ и строгое соблюдение сроков. В результате мы получили не просто ремонт, а идеально выполненную работу по возведению гипсоконструкций, заливке пола, разводке электрики и выравниванию стен. Спасибо за ваше профессионализм и внимательное отношение к деталям!</span>`,
    },
    {
        photo: review_photo_2,
        name: 'Евгений Черепанов',
        platform_img: mail_icon,
        txt: `<span>Долго мы искали подходящую бригаду для нашего ремонта, и в итоге выбрали компанию СТРОЙЧЕЛ! Наш проект включал в себя возведение гипсоконструкций, заливку пола, разводку электрики и выравнивание стен.<br/>
        Мы были приятно удивлены тем, что нам предоставили подробную смету, охватывающую все от материалов до расценок на работы. Это было важно для нас при планировании бюджета нашего будущего ремонта, и ни одна другая компания не предоставила нам такой информации.<br/>
        Хотим выразить огромную благодарность Александру Чуватаеву и его бригаде за высокое качество работы и строгое соблюдение сроков. Ваш профессионализм и внимательное отношение к деталям сделали наш ремонт беззаботным и качественным. Большое спасибо!</span>`,
    },
    {
        photo: review_photo_1,
        name: 'Евгений Черепанов',
        platform_img: mail_icon,
        txt: `<span>Было непросто найти подходящую бригаду для нашего ремонта, но, благодаря тщательному поиску, мы остановились на компании СТРОЙЧЕЛ. Нам была предоставлена детальная смета, включающая не только материалы, но и расценки на работы, что помогло нам четко спланировать бюджет. Это было первым сигналом профессионализма.<br/><br/>
        Хочу выразить огромную благодарность Александру Чуватаеву и всей его команде за высокое качество работ и строгое соблюдение сроков. В результате мы получили не просто ремонт, а идеально выполненную работу по возведению гипсоконструкций, заливке пола, разводке электрики и выравниванию стен. Спасибо за ваше профессионализм и внимательное отношение к деталям!</span>`,
    },
    {
        photo: review_photo_2,
        name: 'Евгений Черепанов',
        platform_img: mail_icon,
        txt: `<span>Долго мы искали подходящую бригаду для нашего ремонта, и в итоге выбрали компанию СТРОЙЧЕЛ! Наш проект включал в себя возведение гипсоконструкций, заливку пола, разводку электрики и выравнивание стен.<br/>
        Мы были приятно удивлены тем, что нам предоставили подробную смету, охватывающую все от материалов до расценок на работы. Это было важно для нас при планировании бюджета нашего будущего ремонта, и ни одна другая компания не предоставила нам такой информации.<br/>
        Хотим выразить огромную благодарность Александру Чуватаеву и его бригаде за высокое качество работы и строгое соблюдение сроков. Ваш профессионализм и внимательное отношение к деталям сделали наш ремонт беззаботным и качественным. Большое спасибо!</span>`,
    },

]

export const questions = [
    {
        numb: 1,
        question: 'Выберите тип вашего объекта',
        choices: [
            {
                img: i_1,
                title: 'Новостройка',
                type: 'btn_img',
            },
            {
                img: i_2,
                title: 'Вторичное жилье',
                type: 'btn_img',
            },
        ]
    },
    {
        numb: 2,
        question: 'Выберите тип вашего объекта',
        choices: [
            {
                img: i_21,
                title: 'Панельный дом',
                type: 'btn_img',
            },
            {
                img: i_22,
                title: 'Кирпичный дом',
                type: 'btn_img',
            },
            {
                img: i_23,
                title: 'Каркасно-монолитный дом',
                type: 'btn_img',
            },
        ]
    },
    {
        numb: 3,
        question: 'Выберите состояние вашего помещения',
        info: 'Это позволит нам определить, нужны ли вам демонтажные работы.',
        choices: [
            {
                img: i_31,
                title: 'Голые стены, нет ремонта',
                type: 'btn_img',
            },
            {
                img: i_32,
                title: 'Есть старый ремонт',
                type: 'btn_img',
            },
        ]
    },
    {
        numb: 4,
        question: 'Выберите какой ремонт вам необходим',
        info: 'Косметический ремонт — обновление напольных и настенных покрытий, установку розеток и выключателей.<br/><br/>Капитальный ремонт — частичную перепланировку, кафельные и сантехнические работы, стяжку полов, конструкции из ГКЛ, полный электромонтаж, замену окон и дверей.<br/><br/>Дизайнерский ремонт — приведение углов под 90 градусов, декоративную штукатурку, потолки сложных форм, установку систем кондиционирования, сложные архитектурные элементы.',
        choices: [
            {
                img: i_41,
                title: 'Косметический ремонт',
                type: 'btn_img',
            },
            {
                img: i_42,
                title: 'Капитальный ремонт',
                type: 'btn_img',
            },
            {
                img: i_43,
                title: 'Дизайнерский ремонт',
                type: 'btn_img',
            },
            {
                img: i_44,
                title: 'Нужна консультация',
                type: 'btn_img',
            },
        ]
    },
    {
        numb: 5,
        question: 'Укажите площадь вашего помещения в кв.м.',
        choices: [
            {
                unic: 'area',
                placeholder: 'Введите площадь',
                type: 'input',
                inputType: 'text',
            },
        ]
    },
    {
        numb: 6,
        question: 'Есть ли у вас дизайн-проект?',
        choices: [
            {
                title: 'Да',
                type: 'btn_txt',
            },
            {
                title: 'Нет',
                type: 'btn_txt',
            },
            {
                title: 'Нужен проект',
                type: 'btn_txt',
            },
        ]
    },
    {
        numb: 7,
        question: 'Когда планируете начать ремонт?',
        choices: [
            {
                title: 'В этом месяце',
                type: 'btn_txt',
            },
            {
                title: 'В ближайшие 2-4 месяца',
                type: 'btn_txt',
            },
            {
                title: 'Через полгода',
                type: 'btn_txt',
            },
            {
                title: 'Через год',
                type: 'btn_txt',
            },
        ]
    },
    {
        numb: 8,
        question: 'Выберите 1 подарок на выбор',
        choices: [
            {
                img: i_81,
                title: 'Монтаж натяжного потолка до 15 кв. м. без электрики',
                type: 'btn_img',
            },
            {
                img: i_82,
                title: 'Укладка ламината в комнате до 15 кв. м.',
                type: 'btn_img',
            },
        ]
    },
    {
        numb: 9,
        choices: [
            {
                unic: 'name',
                placeholder: 'Ваше имя',
                type: 'input',
                inputType: 'text',
            },
            {
                unic: 'tel',
                type: 'inputMask',
                inputType: 'text',
            },
            {
                unic: 'email',
                placeholder: 'Введите email',
                type: 'input',
                inputType: 'text',
            },
        ]
    },
]

export const main_Root = 'main'


import React, { useEffect } from "react"
import { Link, useLocation } from 'react-router-dom';

const Header = () => {

    const location = useLocation()
    const isMain = location.pathname === '/'

    return (
        <div className="header-row" style={!isMain ? {padding: '35px 0 70px 0'} : {}}>
            <div className="header-logo">
                <Link to={'/'} className="logo"></Link>
                <div className={`logo-txt h-12-400-m ${isMain ? 'white' : 'black'}`}>Делаем ремонт в квартирах и <br />коттеджах с 2006 года</div>
            </div>
            <div className="header-main">
                <div className="header-a-example">
                    <span className="a-img-est"></span>
                    <a href="#!" className={`a-txt ${isMain ? 'white' : 'black'}`}>Посмотреть пример реальной сметы</a>
                </div>
                <div className="header-a-example">
                    <span className="a-img-cnt"></span>
                    <a href="#!" className={`a-txt ${isMain ? 'white' : 'black'}`}>Ознакомиться с договором</a>
                </div>
            </div>
            <div className="header-tel">
                <span className="tel-img"></span>
                <a className="white h-18-400-m" href="tel:+73512002040">+7 (351) 200-20-40</a>
            </div>
        </div>

    )
}

export default Header;
import React, { useState } from "react"
import { Splide, SplideTrack, SplideSlide} from '@splidejs/react-splide'
import { Link, useLocation } from "react-router-dom";
import objects1, { url } from "../../utils/consts";
import ApiPost from "../../API/ApiPost";
import { useEffect } from "react";
import { useFetching } from "../../hooks/useFetching";


const Obj_example = () => {

    const [response, setResponse] = useState([])
    const [page, setPage] = useState(1)
    const [quantityPage, setQuantityPage] = useState('')
    const location = useLocation()

    const [fetching, isLoading] = useFetching( async () => {
        const res = await ApiPost.get_cases(page)
        setQuantityPage(res.data.quantity_pages)
        if(response.length > 0) {
            setResponse([...response, ...res.data.data])
        } else {
            setResponse(res.data.data)
        }
        console.log('res', res)
    })

    useEffect(() => {
        fetching()
    }, [page])

    return (
        <div className="example-col-1">
            {response?.map((el) => (
            <div className="obj-example" key={el.id}>
                <div>
                    <div className="splide-block">
                        {el.images.length > 0 &&
                        <Splide hasTrack={ false } options={{
                            rewind: true,
                            gap: '1rem',
                            perPage: 1,
                            pagination: true,
                        }}>
                            <SplideTrack>
                                {el.images?.map((img) => (
                                    <SplideSlide key={img.id}><img src={`${url}${img.image}`}/></SplideSlide>
                                ))}
                            </SplideTrack>

                            <div className="splide__arrows">
                                <button className="splide__arrow splide__arrow--prev"></button>
                                <button className="splide__arrow splide__arrow--next"></button>
                            </div>
                        </Splide>
                        }
                    </div>
                </div>
                <div className="obj-info-col">
                    <span className="h-20-600-m black" dangerouslySetInnerHTML={{ __html: el?.title }} />
                    <div className="obj-info-tegs h-15-600-m">
                        {el.additional_attributes?.map((teg) => (
                            <span key={teg.id}>{teg.title}</span>
                        ))}
                    </div>
                    <div className="obj-info-example">
                        <span className="h-15-600-m">{el.attributes[0]?.title}</span>
                        <span className="h-16-400-m" dangerouslySetInnerHTML={{ __html: el.attributes[0]?.description }} />
                    </div>
                    <div style={{display: "flex", gap: '20px'}}>
                        <div className="obj-info-example">
                            <span className="h-15-600-m">{el.attributes[1]?.title}</span>
                            <span className="h-16-400-m" dangerouslySetInnerHTML={{ __html: el.attributes[1]?.description }} />
                        </div>
                        <div className="obj-info-example">
                            <span className="h-15-600-m">{el.attributes[2]?.title}</span>
                            <span className="h-16-400-m" dangerouslySetInnerHTML={{ __html: el.attributes[2]?.description }} />
                        </div>
                    </div>
                    <Link className="consult-btn h-18-500-m" to='/Consult_po' state={{prevLocation: location}}>Бесплатная консультация</Link>
                </div>
            </div>
            ))}
            {page < quantityPage &&
            <div style={{margin: '100px auto 0 auto'}}>
                <button className="more-cases-btn h-18-500-m" onClick={() => setPage(page + 1)}>Показать больше кейсов</button>
            </div>
            }
        </div>
    )
}

export default Obj_example;
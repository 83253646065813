import React, { useEffect } from "react"
import Header from "./components/Header";
import img_pack from "../img/Img";
import objects1, { Page_privacyPolicy_Root, orderWork, princip1, princip2, princip3, princip4, reviews_slider_pack } from "../utils/consts";
import Obj_example from "./components/Obj_example";
import { useState } from "react";
import InputMask from 'react-input-mask';
import Ow_example from "./components/Ow_example";
import Reviews_slider from "./components/Reviews_slider";
import YandexMap from "./components/YandexMap";
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useFetching } from "../hooks/useFetching";
import ApiPost from "../API/ApiPost";

const Main = () => {
    const [check, setCheck] = useState(false)
    const [currentPrincip, setCurrentPrincip] = useState('1')

    const navigate = useNavigate()
    const location = useLocation()

    function Princip_check(e) {
        let Principles = document.querySelectorAll('.princip')
        Principles.forEach((el) => {
            if(el.classList.contains('active')) {
                el.classList.remove('active')
            }
        })
        e.currentTarget.classList.add('active')
        setCurrentPrincip(e.target.getAttribute('numb'))
    }

    const [fetching, isLoading] = useFetching( async (obj) => {
        const res = await ApiPost.email_send(obj)
    })

    function Submit(e) {
        e.preventDefault()

        const name = e.target.elements.name?.value;
        const phone = e.target.elements.phone?.value;
        const email = e.target.elements.email?.value;
        const check1 = e.target.elements.check?.classList.contains('active');
        
        let obj = {
            name: name,
            phone: phone,
            email: email,
            
            subject: "Новый заявка! (форма на странице)",
            message: ``
        }
        
        if(check1 !== undefined) {
            if(check1) {
                fetching(obj)
            }
        } else {
            fetching(obj)
        }
    }

    function Accordion_click(e) {
        const curSpan = e.currentTarget.querySelector('span')
        const curContent = e.currentTarget.parentElement.querySelector('.accordion-content')
        const curContentHeight = curContent.scrollHeight;
        if(curContent.classList.contains('open')) {
            curContent.classList.remove('open')
            curContent.style.maxHeight = '0';
        } else {
            curContent.classList.add('open')
            curContent.style.maxHeight = curContentHeight + 'px';
        }
        if(curSpan.classList.contains('open')) {
            curSpan.classList.remove('open')
            curContent.style.maxHeight = '0';
        } else {
            curSpan.classList.add('open')
            curContent.style.maxHeight = curContentHeight + 'px';
        }
    }

    // resize

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        console.log(windowWidth)
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Убираем слушатель события при размонтировании компонента
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <div className="offer">
                <div className="container">
                    <div className="offer-content">
                        <Header />
                        <div className="offer-main">
                            <div>
                                <span className="white h-24-400-m">— Планируете ремонт?</span>
                                <h4 className="white h-52-700-m">Сделаем ремонт квартиры «под ключ» в Челябинске</h4>
                                <div>
                                    <div style={{position: 'relative'}}>
                                        <Link className="offer-btn h-18-500-m" to='/Quiz_po' state={{prevLocation: location}}>Рассчитать стоимость ремонта</Link>
                                        {/* <button type="button" className="offer-btn h-18-500-m">Рассчитать стоимость ремонта</button> */}
                                        <div className="gift"/>
                                    </div>
                                    <span className="h-14-500-m white-70">Ответьте на 9 вопросов и получите профессиональную консультацию бесплатно!</span>
                                </div>
                                <div className="offer-footer">
                                    <div className="icons-row">
                                        <div>
                                            <img src={img_pack.r1} />
                                        </div>
                                        <span className="icons-row-txt h-14-500-m white">Бесплатный выезд инженера-замерщика и составление сметы  в течение 3 рабочих дней.</span>
                                    </div>
                                    <div className="icons-row">
                                        <div>
                                            <img src={img_pack.r2} />
                                        </div>
                                        <span className="icons-row-txt h-14-500-m white">Оплата работ осуществляется поэтапно в соответствии с договором, который также включает гарантию на выполненные работы в течение 1-3 лет.</span>
                                    </div>
                                    <div className="icons-row">
                                        <div>
                                            <img src={img_pack.r3} />
                                        </div>
                                        <span className="icons-row-txt h-14-500-m white">Предоставляем комплексные услуги ремонта "под ключ".</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="w_ph_4" />
                            </div>
                        </div>
                        {/* <div className="offer-footer">
                            <div className="icons-row">
                                <div>
                                    <img src={img_pack.r1} />
                                </div>
                                <span className="icons-row-txt h-14-500-m white">Бесплатный выезд инженера-замерщика и смета в течении 3-х рабочих дней</span>
                            </div>
                            <div className="icons-row">
                                <div>
                                    <img src={img_pack.r2} />
                                </div>
                                <span className="icons-row-txt h-14-500-m white">Поэтапная оплата работ по договору с гарантией от 1 до 3 лет</span>
                            </div>
                            <div className="icons-row">
                                <div>
                                    <img src={img_pack.r3} />
                                </div>
                                <span className="icons-row-txt h-14-500-m white">Выполняем ремонт комплексно — «под ключ»</span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="block base">
                <div className="container">
                    <div className="block-content">
                        <div className="block-title">
                            <span className="h-30-700-m">Работаем с 2008 года,<br /></span>
                            <span className="h-30-600-m">реализовали более 108 объектов<br /></span>
                            <span className="h-18-500-m">
                                Все объекты с ремонтом различного типа и сложности,<br />
                                посмотрите на некоторые из них
                            </span>
                        </div>
                        <Obj_example />
                    </div>
                </div>
            </div>
            <div className="block i_1">
                <div className="container">
                    <div className="block-content">
                        <div className="block-title">
                            <span className="h-30-700-m">Сэкономим до 20% бюджета<br /></span>
                            <span className="h-30-600-m">разработав дизайн-проект<br /></span>
                        </div>
                        <div className="example-row-1">
                                <div className="icons-row">
                                    <img className="quote" src={img_pack.quote}/>
                                    <div className="col-30">
                                        <span className="h-18-400-m">В мире ремонта...</span>
                                        <span className="h-16-400-m">... и дизайна каждая деталь имеет значение, каждый штрих - это часть большого художественного полотна, которое мы создаем, превращая обычное пространство в уникальное и уютное место. И в этом процессе ключевую роль играет дизайн-проект.</span>
                                        <span className="h-16-400-m">Некоторые могут сомневаться в пользе дизайн-проекта, считая его лишним излишеством, но на самом деле он - неотъемлемая часть успешного ремонта. Ведь благодаря дизайн-проекту мы можем точно представить, как будет выглядеть конечный результат, избежать недоразумений и ошибок исполнения.</span>
                                        <span className="h-16-400-m">К тому же, экономия времени - это экономия денег. Один и тот же ремонт, выполненный с дизайн-проектом, обходится значительно дешевле (до 20%), чем без него. А значит, инвестиция в качественный дизайн-проект окупается не только эстетически, но и финансово.</span>
                                        <span className="h-24-400-m">Оставьте заявку на бесплатную <br />консультацию с дизайнерами:</span>
                                        <Link className="consult-btn h-18-500-m" to='/Consult_po' state={{prevLocation: location}}>Бесплатная консультация</Link>
                                    </div>
                                </div>
                                <div>
                                    <img src={img_pack.block_3_img} />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pre-block-title" >
                <span className="h-30-700-m">4 отличительных принципа<br /></span>
                <span className="h-30-600-m">за которые нас выбирают</span>
                <img className="pre_block_icon" src={img_pack.pre_block_icon} />
            </div>
            <div className="block i_2">
                <div className="container" style={{minHeight: '837px'}}>
                    <div className="w_ph" />
                    {windowWidth > 550 &&
                    <div className="principles">
                        <button className="princip h-16-500-m active" onClick={Princip_check}>
                            <span numb={1}>1 принцип</span>
                        </button>
                        <button className="princip h-16-500-m" onClick={Princip_check}>
                            <span numb={2}>2 принцип</span>
                        </button>
                        <button className="princip h-16-500-m" onClick={Princip_check}>
                            <span numb={3}>3 принцип</span>
                        </button>
                        <button className="princip h-16-500-m" onClick={Princip_check}>
                            <span numb={4}>4 принцип</span>
                        </button>
                    </div>
                    }
                    <div className="princip_block">
                        {windowWidth > 550 &&
                        <div className="princip_title h-24-700-m" dangerouslySetInnerHTML={{ __html: currentPrincip === '1' ?
                            `1. Бережём ваше время, деньги и нервы, <br/>выполняя весь комплекс работ`
                            : currentPrincip === '2' ?
                            `2. Законно и качественно: соблюдаем стандарты<br/> и превосходим требования СНиП` 
                            : currentPrincip ==='3' ?
                            `3. Работаем, чтобы с гордостью<br/> демонстрировать и рассказывать о<br/> результатах` 
                            : `4. Не работаем в жилых объектах<br/> и обеспечиваем наилучший опыт сотрудничества` }}>
                        </div>
                        }
                        {windowWidth > 550 &&
                        <div className="princip_content">
                        {(currentPrincip === '1' ?
                            princip1
                            : currentPrincip === '2' ?
                            princip2
                            : currentPrincip ==='3' ?
                            princip3
                            : princip4).map((el) => (
                                <div key={el.title} className="icons-row">
                                    <div>
                                        <img src={el.img} />
                                    </div>
                                    <div className="col-15">
                                        <div className="h-16-600-m">{el.title}</div>
                                        <div className="h-16-400-m">{el.desc}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        }
                        {windowWidth <= 550 &&
                        <>
                            <div className="princip_title h-24-700-m">
                                1. Экономим время, деньги и нервы,<br/> выполняя весь комплекс работ
                            </div>
                            <div className="princip_content">
                            {princip1.map((el) => (
                                    <div key={el.title} className="icons-row">
                                        <div>
                                            <img src={el.img} />
                                        </div>
                                        <div className="col-15">
                                            <div className="h-16-600-m">{el.title}</div>
                                            <div className="h-16-400-m">{el.desc}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="princip_title h-24-700-m">
                                2. Законно и качественно: соблюдаем стандарты<br/> и превосходим требования СНиП
                            </div>
                            <div className="princip_content">
                            {princip2.map((el) => (
                                    <div key={el.title} className="icons-row">
                                        <div>
                                            <img src={el.img} />
                                        </div>
                                        <div className="col-15">
                                            <div className="h-16-600-m">{el.title}</div>
                                            <div className="h-16-400-m">{el.desc}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="princip_title h-24-700-m">
                                3. Работаем, чтобы с гордостью<br/> демонстрировать и рассказывать о<br/> результатах
                            </div>
                            <div className="princip_content">
                            {princip3.map((el) => (
                                    <div key={el.title} className="icons-row">
                                        <div>
                                            <img src={el.img} />
                                        </div>
                                        <div className="col-15">
                                            <div className="h-16-600-m">{el.title}</div>
                                            <div className="h-16-400-m">{el.desc}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="princip_title h-24-700-m">
                                4. Не работаем в жилых объектах<br/> и обеспечиваем наилучший опыт сотрудничества
                            </div>
                            <div className="princip_content">
                            {princip4.map((el) => (
                                    <div key={el.title} className="icons-row">
                                        <div>
                                            <img src={el.img} />
                                        </div>
                                        <div className="col-15">
                                            <div className="h-16-600-m">{el.title}</div>
                                            <div className="h-16-400-m">{el.desc}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                        }
                        <form onSubmit={Submit}>
                            <div className="row-30">
                                <InputMask name="phone" className="base_input h-20-500-m" mask="+7 (999) 999-99-99" placeholder="+7 (999) 999-99-99"/>
                                <button className="princip_btn h-18-500-m">Услышать руководителя</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            <div className="block i_1">
                <div className="container">
                    <div className="block-content">
                        <div className="block-title">
                            <span className="h-30-700-m">Виды ремонта<br /></span>
                            <span className="h-30-600-m">которые мы предоставляем <br /></span>
                        </div>
                        <div className="example-row-2">
                            <div className="type_example">
                                <div className="type_example_img">
                                    <img src={img_pack.type_1} alt="back" />
                                    <img src={img_pack.type_1_w} alt="front" style={{minWidth:'415px', left: '50%', transform: 'TranslateX(-50%)', bottom: 0}}/>
                                </div>
                                <div className="type_example_content">
                                    <div className="h-20-700-m black">
                                        Косметический <br/>ремонт
                                    </div>
                                    <div className="type_example_content-txt h-15-400-m">
                                        <span>— Шпаклевка стен</span>
                                        <span>— Наклейка обоев</span>
                                        <span>— Настил линолеума, ламината</span>
                                        <span>— Установка плинтусов</span>
                                        <span>— Окрашивание потолков</span>
                                        <span>— Монтаж натяжных потолков</span>
                                        <span>— Установка розеток и выключателей</span>
                                        <span>— Окраска окон, дверей, батарей</span>
                                        <span>— Прочие работы</span>
                                    </div>
                                </div>
                            </div>
                            <div className="type_example">
                                <div className="type_example_img">
                                    <img src={img_pack.type_2} alt="back" />
                                    <img src={img_pack.type_2_w} alt="front" style={{scale: '1.1', right: 0}}/>
                                </div>
                                <div className="type_example_content">
                                    <div className="h-20-700-m black">
                                        Капитальный <br/>ремонт
                                    </div>
                                    <div className="type_example_content-txt h-15-400-m">
                                        <span>— Включает косметический ремонт</span>
                                        <span>— Частичная перепланировка</span>
                                        <span>— Кафельные работы</span>
                                        <span>— Стяжка полов</span>
                                        <span>— Конструкции ГКЛ</span>
                                        <span>— Замена батарей, труб ХГВС</span>
                                        <span>— Установка сантехники</span>
                                        <span>—Полный электромонтаж</span>
                                        <span>— Замена окон, установка дверей</span>
                                    </div>
                                </div>
                            </div>
                            <div className="type_example">
                                <div className="type_example_img">
                                    <img src={img_pack.type_3} alt="back" />
                                    <img src={img_pack.block_3_img} alt="front" style={{ scale: '0.5',left: '-60px', bottom: '-80px'}}/>
                                </div>
                                <div className="type_example_content">
                                    <div className="h-20-700-m black">
                                        Косметический <br/>ремонт
                                    </div>
                                    <div className="type_example_content-txt h-15-400-m">
                                        <span>— Включает капитальный ремонт</span>
                                        <span>— Приведение углов под 90 градусов</span>
                                        <span>— Декоративная штукатурка</span>
                                        <span>— Лепнина и мозайка</span>
                                        <span>— Художественная роспись</span>
                                        <span>— Потолки сложных форм</span>
                                        <span>— Установка систем вентиляции</span>
                                        <span>— Установка кондиционеров</span>
                                        <span>— Сложные архитектурные элементы</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{margin: '0 auto'}}>
                            <Link className="quiz_btn h-18-500-m" to='/Quiz_po' state={{prevLocation: location}}>Рассчитать стоимость ремонта</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="block i_3">
                <div className="container">
                    <div className="block-content">
                        <div className="block-title">
                            <span className="h-30-700-m white">Как будет происходить <br />работа над вашим проектом</span>
                        </div>
                        <div className="example-row-3">
                            {orderWork.map((el,i) => (
                                <Ow_example key={el.title} el={el} i={i} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="block i_4">
                <div className="container" style={{height: '883px'}}>
                {/* <div className="w_ph_2" /> */}
                    <div className="block-content">
                        <div className="block-title">
                            <span className="h-30-700-m">Бесплатно сделаем замер и рассчитаем<br /></span>
                            <span className="h-30-600-m">смету в течении 3 дней<br /></span>
                        </div>
                        <div className="example-row-4">
                            <div className="col-20">
                                <div className="h-18-400-m">После того, как вы оставите заявку, с вами свяжется:</div>
                                <div className="row-a-15">
                                    <img src={img_pack.manager} />
                                    <div className="col">
                                        <span className="h-16-700-m">Юлия</span>
                                        <span className="h-14-400-m">Ваш менеджер</span>
                                    </div>
                                </div>
                                <div className="h-14-400-m">и согласует время приезда мастера</div>
                                <form className="form-20" onSubmit={Submit}>
                                    <InputMask name="phone" className="base_input h-20-500-m" mask="+7 (999) 999-99-99" placeholder="+7 (999) 999-99-99" required/>
                                    <div className="row-a-15">
                                        <button name="check" type="button" className={`checkbox_btn ${check ? 'active' : ''}`} onClick={() => setCheck(!check)}></button>
                                        <span className="h-14-400-m">Я согласен с <Link to={`/${Page_privacyPolicy_Root}`} className="orange" style={{textDecoration: 'underline'}}>политикой конфиденциальности</Link></span>
                                    </div>
                                    <div>
                                        <button type="submit" className="submit_btn h-18-500-m">Пригласить на замер</button>
                                    </div>
                                </form>
                            </div>
                            {windowWidth > 1000 &&
                            <div className="col-20">
                                <div className="min-block">
                                    <div className="h-18-400-m">После замера Вы получаете</div>
                                    <img src={img_pack.papers_clips} style={{width: '140px', height: '207px'}}/>
                                    <div className="h-16-400-m">точный расчёт стоимости и сроков ремонта в течение 3 дней</div>
                                </div>
                            </div>
                            }
                            {windowWidth > 1000 &&
                            <div className="col-10" style={{alignItems: 'flex-start'}}>
                                <img src={img_pack.quote} />
                                <div className="h-14-400-m">
                                    В 95% случаев расчёт стоимости в смете соответствует реальной стоимости работ, однако мы рекомендуем оставить дополнительные 20% на случай форс-мажорных обстоятельств, чтобы обеспечить финансовую гибкость и избежать непредвиденных расходов.
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="block i_5">
                <div className="container" style={windowWidth > 1150 ? {height: '768px'} : {height: '100%'}}>
                    <div className="w_ph_3" />
                    <div className="r-block">
                        <div className="h-30-700-m white">Посмотрите, как мы работаем</div>
                        <div className="h-18-400-m white">Запишитесь на просмотр объектов в работе, познакомьтесь<br/> с нашими специалистами, ознакомьтесь<br/> с примерными ценами.</div>
                        <div className="col-15">
                            <div className="h-18-400-m white">Также вы получите:</div>
                            <div className="row-30">
                                <div className="row-a-15">
                                    <img src={img_pack.p_1} />
                                    <span className="h-14-500-m white">Бесплатную подробную консультацию по ремонту квартиры или дома</span>
                                </div>
                                <div className="row-a-15">
                                    <img src={img_pack.p_2} />
                                    <span className="h-14-500-m white">Бесплатную оценку и разбор сметы конкурентов по вашему объекту</span>
                                </div>
                            </div>
                        </div>
                        <Link className="consult-btn h-18-500-m" to='/Consult_po' style={{margin: '0 auto'}} state={{prevLocation: location}}>Бесплатная консультация</Link>
                    </div>
                </div>
            </div>
            <div className="block i_or">
                <div className="container">
                    <div className="block-content" style={{gap: '30px'}}>
                        <div className="block-title">
                            <span className="h-30-700-m">Ответьте на 9 вопросов<br /></span>
                            <span className="h-30-600-m">и получите 1 из 3 подарков на выбор!</span>
                        </div>
                        <Link className="gift_b h-18-500-m" to='/Quiz_po' state={{prevLocation: location}} />
                        {/* <button className="gift_b" /> */}
                        <span className="h-18-400-m black" style={{alignSelf: 'center', textAlign: 'center'}}>Нажимай на подарок!<br/>Ценные подарки внутри!</span>
                    </div>
                </div>
            </div>
            <div className="block base">
                <div className="container">
                    <div className="block-content">
                        <div className="block-title">
                            <span className="h-30-700-m">Почитайте, что о нас пишут<br /></span>
                            <span className="h-30-600-m">на открытых площадках</span>
                        </div>
                        <div className="col-50">
                            <Reviews_slider />
                            {/* <div className="row-30 review_star_row" style={{maxWidth: '960px', alignSelf: 'center', width: '100%', justifyContent: 'space-between'}}>
                                <div className="review_star_example">
                                    <img src={img_pack.stars_icon} alt="stars" />
                                    <span className="h-14-400-m">5.0 на Flamp</span>
                                    <div>
                                        <img src={img_pack.flamp} alt="flamp" />
                                    </div>
                                    <button className="review_btn h-14-500-m">Читать отзывы</button>
                                </div>
                                <div className="review_star_example">
                                    <img src={img_pack.stars_icon} alt="stars" />
                                    <span className="h-14-400-m">5.0 на 2GIS</span>
                                    <div>
                                        <img src={img_pack.dgis} alt="dgis" />
                                    </div>
                                    <button className="review_btn h-14-500-m">Читать отзывы</button>
                                </div>
                                <div className="review_star_example">
                                    <img src={img_pack.stars_icon} alt="stars" />
                                    <span className="h-14-400-m">5.0 на Google Maps</span>
                                    <div>
                                        <img src={img_pack.gogle_maps} alt="gogle_map" />
                                    </div>
                                    <button className="review_btn h-14-500-m">Читать отзывы</button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="block base">
                <div className="container">
                    <div className="block-content">
                        <div className="block-title">
                            <span className="h-30-700-m">Вопросы которые нам<br /></span>
                            <span className="h-30-600-m">задают чаще всего</span>
                        </div>
                        <div className="accordion-block">
                            <div className="accordion-example">
                                <div className="accordion-title" onClick={Accordion_click}>
                                    <div className="h-18-600-m">Как происходит оплата работ?</div>
                                    <span></span>
                                </div>
                                <div className="accordion-content h-16-400-m black">
                                    При заключении договора вносится предоплата, затем 2 раза в месяц закрываются акты выполненных работ, в соответствии с которыми вносится оплата. <Link className="orange" style={{textDecoration: 'underline'}} to='/Consult_po' state={{prevLocation: location}}>Оставьте заявку на консультацию →</Link>
                                </div>
                            </div>
                            <div className="accordion-line" />
                            <div className="accordion-example">
                                <div className="accordion-title" onClick={Accordion_click}>
                                    <div className="h-18-600-m">Кто будет заниматься закупкой и доставкой черновых материалов?</div>
                                    <span></span>
                                </div>
                                <div className="accordion-content h-16-400-m black">
                                    Закупкой и доставкой черновых материалов можем заняться мы, сняв с вас нагрузку по беготне.<br/><br/>По всем закупленным материалам будет произведен отчет: все чеки предоставляются после покупки.
                                </div>
                            </div>
                            <div className="accordion-line" />
                            <div className="accordion-example">
                                <div className="accordion-title" onClick={Accordion_click}>
                                    <div className="h-18-600-m">Какую гарантию вы предоставляете?</div>
                                    <span></span>
                                </div>
                                <div className="accordion-content h-16-400-m black">
                                    Мы официально заключаем договор, в котором указаны условия предоставления гарантии.<br/><br/>Гарантийный срок для отделочных работ — 1 год, на срытые электромонтажные и сантехнические работы 3 года.
                                </div>
                            </div>
                            <div className="accordion-line" />
                            <div className="accordion-example">
                                <div className="accordion-title" onClick={Accordion_click}>
                                    <div className="h-18-600-m">Как быстро вы сможете приступить к работе?</div>
                                    <span></span>
                                </div>
                                <div className="accordion-content h-16-400-m black">
                                    С момента заключения договора мы обычно приступаем к работам в течении 5 рабочих дней.<br/><br/>Это время необходимо на организацию персонала.<br/><br/><Link className="orange" style={{textDecoration: 'underline'}} to='#!' state={{prevLocation: location}}>Прочитать договор →</Link>
                                </div>
                            </div>
                            <div className="accordion-line" />
                            <div className="accordion-example">
                                <div className="accordion-title" onClick={Accordion_click}>
                                    <div className="h-18-600-m">Как быстро я получу расчет стоимости ремонта?</div>
                                    <span></span>
                                </div>
                                <div className="accordion-content h-16-400-m black">
                                    В течении 3 рабочих дней с момента выезда специалиста на замер вашего помещения или его осмотра при наличии дизайн проекта.<br/><br/><Link className="orange" style={{textDecoration: 'underline'}} to='#!' state={{prevLocation: location}}>Получить расчет стоимости →</Link>
                                </div>
                            </div>
                            <div className="accordion-line" />
                            <div className="accordion-example">
                                <div className="accordion-title" onClick={Accordion_click}>
                                    <div className="h-18-600-m">Кто будет заниматься закупкой чистовых материалов?</div>
                                    <span></span>
                                </div>
                                <div className="accordion-content h-16-400-m black">
                                    Закупкой чистовых материалов обычно занимается сам заказчик, мы можем посоветовать где что покупать.<br/><br/>Если имеется дизайн-проект, то лучше это доверить дизайнерам.
                                </div>
                            </div>
                        </div>
                        <div className="example-col-2">
                            <img src={img_pack.manager} style={{width: 'max-content'}}/>
                            <div className="col-10">
                                <span className="h-20-500-m">Остались вопросы?</span>
                                <span className="h-18-400-m g_1">Оставьте свой номер телефона, наш менеджер перезвонит и ответит на все интересующие Вас вопросы. </span>
                            </div>
                            <Link className="call_btn h-14-500-m black" to='/Call_po' state={{prevLocation: location}}>Перезвоните мне</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="block">
                <div className="container">
                    <div className="block-content">
                        <div className="example-row-5">
                            <div className="col-50">
                                <div className="block-title">
                                    <span className="h-30-700-m">Доверьте свой ремонт<br /></span>
                                    <span className="h-30-600-m">профессионалам</span>
                                </div>
                                <div className="col-20">
                                    <div className="h-15-400-m">Работаем без выходных в городе Челябинск</div>
                                    <div className="col-10">
                                        <div className="h-40-500-m black">+7 (351) 200-20-40</div>
                                        <Link className="h-14-500-m orange" to='/Call_po' state={{prevLocation: location}} style={{textDecoration: 'underline'}}>Перезвоните мне →</Link>
                                    </div>
                                    <div className="row-a-20" style={{alignSelf: 'center'}}>
                                        <img src={img_pack.Callmail_icon} alt="mail-icon" />
                                        <span className="h-20-500-m black">info@x-remont74.ru</span>
                                    </div>
                                    {/* <span className="h-15-400-m">Наши социальные сети</span>
                                    <div className="socials">
                                        <a href="#!" className="social-example">
                                            <div className="row-a-10">
                                                <img src={img_pack.vk_icon} alt="vk_icon" />
                                                <span className="h-14-500-m black">Вконтакте</span>
                                            </div>
                                        </a>
                                        <a href="#!" className="social-example">
                                            <div className="row-a-10">
                                                <img src={img_pack.facebook_icon} alt="facebook_icon" />
                                                <span className="h-14-500-m black">Facebook</span>
                                            </div>
                                        </a>
                                        <a href="#!" className="social-example">
                                            <div className="row-a-10">
                                                <img src={img_pack.inst_icon} alt="inst_icon" />
                                                <span className="h-14-500-m black">Instagram</span>
                                            </div>
                                        </a>
                                        <a href="#!" className="social-example">
                                            <div className="row-a-10">
                                                <img src={img_pack.youtube_icon} alt="youtube_icon" />
                                                <span className="h-14-500-m black">YouTube</span>
                                            </div>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                            <div className="Ymap_cotnainer">
                                <YandexMap width={windowWidth}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main;
import React from "react"
import Header from "./components/Header";
import { Link } from "react-router-dom";

const Page404 = () => {

    return (
        <>
            <div className="block i_2">
                <div className="container stretch" style={{height: '750px'}}>
                    <div className="w_ph_4" />
                    <Header />
                    <div className="block404">
                        <div className="h-140-700-m orange">404</div>
                        <div className="h-25-700-m">Упс. Кажется такой страницы не существует.</div>
                        <div className="h-16-400-m">Такое могло произойти, если страница была удалена или вы перешли по несуществующей ссылке.</div>
                        <Link className="main_btn h-18-500-m" to='/'>Вернуться на главную</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Page404;